<template>
  <v-container fluid>
    <v-row class="mt-2 px-12" v-if="dataLoading">
      <v-col cols="12">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" v-for="i in 12" :key="i + 12">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-form @submit.prevent ref="form">
        <v-card-title class="primary--text">
          New Leave Application
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                auto-select-first
                flat
                v-model="application.type"
                :items="leaves"
                item-text="name"
                item-value="key"
                label="Leave Type"
                outlined
                :rules="[rules.required]"
                @change="updateSelected"
              >
                <template v-slot:item="data">
                  {{ data.item.name }}
                  <v-chip class="ms-3" small v-if="data.item.count > 0">
                    {{ data.item.count }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                v-model="menu1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    flat
                    outlined
                    label="From Date *"
                    v-model="application.from_date"
                    :rules="[rules.futureDate, rules.required, rules.dayBEF]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="application.from_date"
                  color="primary"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                v-model="menu2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    flat
                    outlined
                    label="To Date *"
                    v-model="application.to_date"
                    :rules="[rules.futureDate, rules.required]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="application.to_date"
                  color="primary"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                flat
                outlined
                label="No of days *"
                type="number"
                v-model="application.no_of_days"
                :rules="[
                  rules.required,
                  rules.lessThanSelected,
                  rules.lessThanMax,
                  rules.moreThanZero,
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                flat
                outlined
                label="Department/Location *"
                v-model="application.department"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-textarea
                flat
                outlined
                label="Reason"
                v-model="application.reason"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
            cancel
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="updateLeaveApplication"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-title>
      </v-form>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";

import { editLeaveApplication } from "@/services/leaveApplicationsService";
import {
  getMyLeaveApplication,
  fetchAndStoreMyLeaveApplications,
  fetchAndStoreMyLieuApplications,
} from "@/services/myDataService";
import constants from "@/constants.json";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    loading: false,
    dataLoading: false,
    rules: {},
    application: {
      uuid: "",
      staff_uuid: "",
      from_date: "",
      to_date: "",
      no_of_days: null,
      department: "",
      type: "",
      reason: "",
      review_status: "PENDING",
    },
    leaves: [],
    myLeaves: [],
    myLieus: [],
    selectedType: null,
  }),
  async created() {
    this.rules = {
      required: (value) => !!value || "Required.",
      moreThanZero: (value) => value > 0 || "Days must be more tan 0.",
      lessThanSelected: (value) => {
        if (this.selectedType)
          return (
            value <= this.selectedType.count ||
            this.selectedType.key == "NOPAY" ||
            `Only ${this.selectedType.count} ${this.selectedType.name} leaves remaining.`
          );
        else return true;
      },
      lessThanMax: (value) => {
        if (this.selectedType)
          return (
            value <= this.selectedType.maxCount ||
            this.selectedType.key == "NOPAY" ||
            `Only ${this.selectedType.maxCount} ${this.selectedType.name} leaves remaining.`
          );
        else return true;
      },
      dayBEF: (value) => {
        if (this.selectedType)
          return (
            this.$_daysDifference(value) >= this.selectedType.BEF ||
            this.selectedType.key == "NOPAY" ||
            `You should apply ${this.selectedType.name} leaves before ${this.selectedType.BEF} days.`
          );
        else return true;
      },
      futureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate > today || "The date must be in the future.";
      },
    };

    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Leave Applications",
        "Update"
      )
    ) {
      this.dataLoading = true;
      this.application = await getMyLeaveApplication(this.$route.params.uuid);
      this.myLeaves = await fetchAndStoreMyLeaveApplications(
        false,
        this.$_getFirstDateOfYear(),
        "",
        '["PENDING", "APPROVED"]'
      );

      this.myLieus = await fetchAndStoreMyLieuApplications(
        false,
        this.formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        this.formatDate(new Date()),
        '["APPROVED"]'
      );
      await this.calculateAnualLeaves();
      await this.calculateCasualLeaves();
      await this.calculateSickLeaves();
      await this.calculateLieuLeaves();
      this.leaves.push({
        name: "No-pay",
        count: 0,
        key: "NOPAY",
      });
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageMyLeaveApplicationList" });
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewLeaveApplication",
        params: { uuid: item.uuid },
        query: { bc: item.name, mylog: "true" },
      });
    },
    async updateLeaveApplication() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let editLeaveApplicationReturn = await editLeaveApplication(
          this.application
        );
        this.loading = false;
        if (editLeaveApplicationReturn == "success") {
          this.viewItem(this.application);
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },

    async calculateCasualLeaves() {
      let casualLeaves = this.myLeaves.filter((item) => item.type == "CASUAL");
      let casualPastCount = 0;

      casualLeaves.forEach((element) => {
        casualPastCount += Number(element.no_of_days);
      });

      let casualCount =
        (
          (Math.min(
            this.$_calculateMonthsWorked(this.auth.date_of_joining),
            12
          ) /
            12) *
          constants.leaves.casual
        ).toFixed(2) - casualPastCount;

      if (casualCount > 0 && this.auth.type == "Regular Contract Staff")
        this.leaves.push({
          name: "Casual",
          count: casualCount,
          maxCount: constants.leaves.casualMAX,
          BEF: constants.leaves.casualBEF,
          key: "CASUAL",
        });
    },
    async calculateAnualLeaves() {
      let annualLeaves = this.myLeaves.filter((item) => item.type == "ANNUAL");
      let annualPastCount = 0;

      annualLeaves.forEach((element) => {
        annualPastCount += Number(element.no_of_days);
      });

      let annualCount =
        (
          (Math.min(
            this.$_calculateMonthsWorked(this.auth.date_of_joining),
            12
          ) /
            12) *
          constants.leaves.annual
        ).toFixed(2) - annualPastCount;

      if (annualCount > 0 && this.auth.type == "Regular Contract Staff")
        this.leaves.push({
          name: "Annual",
          count: annualCount,
          maxCount: constants.leaves.annualMAX,
          BEF: constants.leaves.annualBEF,
          key: "ANNUAL",
        });
    },
    async calculateSickLeaves() {
      let sickLeaves = this.myLeaves.filter((item) => item.type == "SICK");
      let sickPastCount = 0;

      sickLeaves.forEach((element) => {
        sickPastCount += Number(element.no_of_days);
      });
      let sickCount =
        (
          (Math.min(
            this.$_calculateMonthsWorked(this.auth.date_of_joining),
            12
          ) /
            12) *
          constants.leaves.sick
        ).toFixed(2) - sickPastCount;

      if (sickCount > 0 && this.auth.type == "Regular Contract Staff")
        this.leaves.push({
          name: "Sick",
          count: sickCount,
          maxCount: constants.leaves.sickMAX,
          BEF: constants.leaves.sickBEF,
          key: "SICK",
        });
    },
    async calculateLieuLeaves() {
      let lieuLeaves = this.myLieus;
      let lieuCount = 0;

      lieuLeaves.forEach((element) => {
        lieuCount += Number(element.no_of_days);
      });

      if (lieuCount > 0)
        this.leaves.push({
          name: "Lieu",
          count: lieuCount,
          maxCount: lieuCount,
          BEF: constants.leaves.lieuBEF,
          key: "LIEU",
        });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, add 1 and pad with 0
      const day = String(date.getDate()).padStart(2, "0"); // Pad day with 0 if necessary
      return `${year}-${month}-${day}`;
    },
    updateSelected() {
      this.selectedType = this.leaves.find(
        (element) => element.key == this.application.type
      );
    },
  },
  updated() {
    // this.application.no_of_days = this.$_calculateDays(
    //   this.application.from_date,
    //   this.application.to_date
    // );
  },
};
</script>